import React, { useState } from 'react';

export default function App() {

  const [inputText,setInputText] = useState("");
  const [outputText,setOutputText] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const newXML = inputText.split("\n").map((url) => { 
      return url && `<url>
      <loc>${url}</loc>
      <lastmod>2022-04-27</lastmod>
      <changefreq>daily</changefreq>
      <priority>0.9</priority>
      </url>\n`.replace(/ /g,"")
    }).join("");

    setOutputText(newXML);
    setInputText("");
  }

  const handleClear = () => {
    setInputText("");
    setOutputText("");
  }

  return (
    <div style={{margin:"50px"}}>
      <form action="" onSubmit={handleSubmit}>
        <div>
          <textarea className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 p-2.5' cols="80" rows="15" required onChange={(e) => setInputText(e.target.value)} value={inputText} type="text" />
        </div>
        
        <input className='text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2' type='submit'/>

        <button className='text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 mt-2' type='button' onClick={handleClear}>Clear</button>
      </form>
      <div className='relative' style={{marginTop:"20px"}}>
        <textarea className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 p-2.5' cols="80" rows="15" type="text" onChange={(e) => setOutputText(e.target.value)} value={outputText} />

        {/* copy button */}
        <button onClick={() => {navigator.clipboard.writeText(outputText)}} className="absolute left-[53%] top-5 focus:outline-none" type="button" aria-label="To Copy">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-emerald-500 focus:outline-none" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"></path>
          </svg>
        </button>
      </div>
    </div>
  );
}